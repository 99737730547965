/* global __USE_PLAYER_CACHING__ */

import { rotateTool, setRotations } from './rotateTool';
import publicApi from './api';

export async function initPlayer(
  el,
  authToken,
  assetId,
  stageId,
  initialConfiguration,
  options = {}
) {
  console.log(
    'Threekit: Applying initial player configuration - ',
    initialConfiguration
  );
  const params = {
    authToken,
    el,
    assetId,
    // stageId,
    initialConfiguration,
    showConfigurator: false,
    display: 'image',
    analyticsCustomId: options.analyticsCustomId,
    imageFitMode: options.imageFitMode,
    // publishStage: 'draft',
    // cache: {
    //   maxAge: 31536000,
    //   scope: 'v1.11',
    // },
  };
  const playerFunc = window.threekitPlayer2D || window.threekitPlayer;
  const api = await playerFunc(params);

  window.api = api;
  const renderedPromise = new Promise((resolve, reject) => {
    api.on(api.scene?.PHASES.RENDERED || 'rendered', async () => {
      console.log('rendered');
      resolve();
    });
  });

  // api.enableApi('player');

  const configurator = await api.getConfigurator();
  await configurator.prefetchAttributes(['Rotation']);
  window.threekit.playerConfigurator = configurator;

  const rotationAttr = configurator
    .getAttributes()
    .find((attr) => attr.name === 'Rotation');
  if (rotationAttr) {
    setRotations(
      rotationAttr.values.filter((value) =>
        Number.isInteger(Number.parseInt(value))
      )
    );
    api.tools.addTool(rotateTool);
  }
  // api.tools.addTool('zoom');
  api.tools.removeTool('zoom');

  // turnTableMobileOnly is what causes the default orbit tool to scroll on
  // vertical swipe, instead of orbiting the camera vertically. In our case, we
  // don't want/need that behaviour, since our player is not fullscreen so the
  // user can still scroll by just swiping the non-player portion of the view
  // (ie BA's UI). So by setting turnTableMobileOnly to false, we retain the
  // ability for the user to orbit vertically, as requested by the client.
  // api.tools.setTool('orbit', { options: { turnTableMobileOnly: false } });

  moveLogo(el).catch((err) => {
    console.log('Error encountered moving logo url', err);
  });

  // await api.when('loaded');

  // This is a workaround for a bug that occurs when calling setConfiguration
  // too early
  // await api.player.evaluateCompositeAsset();

  // Another approach to avoid the early setConfiguration bug, but this causes a
  // longer delay before it resolves, leading to more of a flash between default
  // config and user-set config
  // await new Promise((resolve, reject) => {
  //   api.on(api.scene.PHASES.RENDERED, async () => {
  //     resolve();
  //   });
  // });

  await renderedPromise;

  return {
    api,
    configurator: publicApi,
    threekitEl: el,
  };
}

const getThreekitLogo = (el) => {
  const elems = Array.from(el.getElementsByTagName('div')).filter(
    (ele) => ele.className.includes('logo') && ele.className.includes('button')
  );

  return elems[0];
};

async function moveLogo(parentElem) {
  const startTime = new Date();

  const check = () => {
    const logo = getThreekitLogo(parentElem);
    if (!logo) {
      if (new Date() - startTime < 120000)
        return new Promise((resolve) => setTimeout(resolve, 50)).then(check);
    } else {
      logo.setAttribute('style', 'top:auto;bottom:8px;left:auto;right:50px');
    }
  };
  return check();
}
